(function () {

	'use strict';

	// iPad and iPod detection	
	var isiPad = function () {
		return (navigator.platform.indexOf("iPad") != -1);
	};

	var isiPhone = function () {
		return (
			(navigator.platform.indexOf("iPhone") != -1) ||
			(navigator.platform.indexOf("iPod") != -1)
		);
	};

	// OffCanvass
	var offCanvass = function () {
		$('body').on('click', '.js-fh5co-nav-toggle', function (event) {

			var $this = $(this);

			$('#fh5co-offcanvass').toggleClass('fh5co-awake');
			$('#fh5co-page, #fh5co-menu').toggleClass('fh5co-sleep');




			if ($('#fh5co-offcanvass').hasClass('fh5co-awake')) {
				$this.addClass('active');
			} else {
				$this.removeClass('active');
			}
			event.preventDefault();

		});
	};

	// Single Page Nav
	var clickMenu = function () {
		$('a:not([class="external"])').click(function () {
			var section = $(this).data('nav-section');
			$('html, body').animate({
				scrollTop: $('[data-section="' + section + '"]').offset().top
			}, 1000);
			return false;
		});
	};

	// Owl Carousel
	var carouselTestimony = function () {

		var owl = $(".owl-carousel");

		owl.owlCarousel({
			items: 1,
			margin: 0,
			responsiveClass: true,
			loop: true,
			nav: true,
			dots: true,
			autoplay: true,
			smartSpeed: 500,
			responsive: {
				0: {
					nav: false
				},
				480: {
					nav: false
				},
				768: {
					nav: false
				},
				1000: {
					nav: true,
				}
			},
			navText: [
				"<i class='icon-arrow-left owl-direction'></i>",
				"<i class='icon-arrow-right owl-direction'></i>"
			]
		});
	};


	var footerFixed = function () {
		var fh = $('#fh5co-footer').innerHeight();
		$('#fh5co-wrap').css({
			marginBottom: fh + 'px'
		});

		if ($(window).width() < 991) {
			$('#fh5co-wrap').css({
				marginBottom: ''
			})
		}

		$(window).resize(function () {

			var fh = $('#fh5co-footer').innerHeight();
			$('#fh5co-wrap').css({
				marginBottom: fh + 'px'
			});

			if ($(window).width() < 991) {
				$('#fh5co-wrap').css({
					marginBottom: ''
				})
			}
		});
	};

	// Counter 
	var counter = function () {
		$('.js-counter').countTo({
			formatter: function (value, options) {
				return value.toFixed(options.decimals);
			},
		});
	};

	//  Faqs Accordion
	var faqsAccordion = function () {

		var faqAcc = $('.faq-accordion h3');

		// Click
		faqAcc.on('click', function (event) {
			var $this = $(this);

			$('.faq-accordion').removeClass('active');
			$('.faq-accordion').find('.faq-body').slideUp(400, 'easeInOutExpo');

			if (!$this.closest('.faq-accordion').find('.faq-body').is(':visible')) {
				$this.closest('.faq-accordion').addClass('active');
				$this.closest('.faq-accordion').find('.faq-body').slideDown(400, 'easeInOutExpo');
			} else {
				$this.closest('.faq-accordion').removeClass('active');
				$this.closest('.faq-accordion').find('.faq-body').slideUp(400, 'easeInOutExpo');
			}


			setTimeout(function () {
				// alert($this.closest('.faq-accordion.active').innerHeight());
				$('html, body').animate({
					scrollTop: $this.closest('.faq-accordion.active').offset().top - 90
				}, 500);
			}, 700);


			event.preventDefault();
			return false;

		});

	};

	// Click outside of offcanvass
	var mobileMenuOutsideClick = function () {
		$(document).click(function (e) {
			var container = $("#fh5co-offcanvass, .js-fh5co-nav-toggle");
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				if ($('#fh5co-offcanvass').hasClass('fh5co-awake')) {
					$('#fh5co-offcanvass').removeClass('fh5co-awake');
					$('#fh5co-page, #fh5co-menu').removeClass('fh5co-sleep');

					$('.js-fh5co-nav-toggle').removeClass('active');
				}
			}
		});

		$(window).scroll(function () {
			var $menu = $('#fh5co-menu');
			if ($(window).scrollTop() > 150) {
				$menu.addClass('sleep');
			}

			if ($(window).scrollTop() < 500) {
				$menu.removeClass('sleep');
				$('#fh5co-offcanvass ul li').removeClass('active');
				$('#fh5co-offcanvass ul li').first().addClass('active');
			}


			if ($(window).scrollTop() > 500) {
				if ($('#fh5co-offcanvass').hasClass('fh5co-awake')) {
					$('#fh5co-offcanvass').removeClass('fh5co-awake');
					$('#fh5co-page, #fh5co-menu').removeClass('fh5co-sleep');

					$('.js-fh5co-nav-toggle').removeClass('active');
				}
			}
		});
	};

	// Magnific Popup

	var magnifPopup = function () {
		$('.image-popup').magnificPopup({
			type: 'image',
			removalDelay: 300,
			mainClass: 'mfp-with-zoom',
			titleSrc: 'title',
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true, // By default it's false, so don't forget to enable it

				duration: 300, // duration of the effect, in milliseconds
				easing: 'ease-in-out', // CSS transition easing function

				// The "opener" function should return the element from which popup will be zoomed in
				// and to which popup will be scaled down
				// By defailt it looks for an image tag:
				opener: function (openerElement) {
					// openerElement is the element on which popup was initialized, in this case its <a> tag
					// you don't need to add "opener" option if this code matches your needs, it's defailt one.
					return openerElement.is('img') ? openerElement : openerElement.find('img');
				}
			}
		});
	};


	// Scroll Animations

	// Intro Animate
	var introWayPoint = function () {
		if ($('#fh5co-hero').length > 0) {
			$('#fh5co-hero').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {

					setTimeout(function () {
						$('.intro-animate-1').addClass('fadeInUp animated');
					}, 100);
					setTimeout(function () {
						$('.intro-animate-2').addClass('fadeInUp animated');
					}, 400);
					setTimeout(function () {
						$('.intro-animate-3').addClass('fadeInUp animated');
						$('.intro-animate-4').addClass('fadeInUp animated');
					}, 700);

					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};

	var HeaderToggle = function () {

		var $this = $('#fh5co-main');
		
		$this.waypoint(function (direction) {
			if (direction === 'down') {
				$('body').addClass('scrolled');
				$('.navbar-brand .fs-logo').addClass('black');
			} else if (direction === 'up') {
				$('body').removeClass('scrolled');
				$('.navbar-brand .fs-logo').removeClass('black');
			}

		}, {
			offset: '20px'
		});

	};


	// Client Animate
	var clientAnimate = function () {
		if ($('#fh5co-clients').length > 0) {
			$('#fh5co-clients .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeIn animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var clientWayPoint = function () {
		if ($('#fh5co-clients').length > 0) {
			$('#fh5co-clients').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {


					setTimeout(clientAnimate, 100);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};

	// Features Animate
	var featuresAnimate = function () {
		if ($('#fh5co-features').length > 0) {
			$('#fh5co-features .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var featuresWayPoint = function () {
		if ($('#fh5co-features').length > 0) {
			$('#fh5co-features').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {


					setTimeout(featuresAnimate, 100);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};


	// Features 2 Animate
	var features2AnimateTitle = function () {
		if ($('#fh5co-features-2').length > 0) {
			$('#fh5co-features-2 .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeIn animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var features2WayPoint = function () {
		if ($('#fh5co-features-2').length > 0) {
			$('#fh5co-features-2').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {


					setTimeout(features2AnimateTitle, 100);

					setTimeout(function () {
						$('.features-2-animate-2').addClass('fadeInUp animated');
					}, 800);

					setTimeout(function () {
						$('.features-2-animate-3').addClass('fadeInRight animated');
						$('.features-2-animate-5').addClass('fadeInLeft animated');
					}, 1200);
					setTimeout(function () {
						$('.features-2-animate-4').addClass('fadeInRight animated');
						$('.features-2-animate-6').addClass('fadeInLeft animated');
					}, 1400);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};


	var counterWayPoint = function () {
		if ($('#fh5co-counter').length > 0) {
			$('#fh5co-counter').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {

					setTimeout(function () {
						$('.counter-animate').addClass('fadeInUp animated');
						counter();
					}, 100);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};


	// Products Animate
	var productsAnimate = function () {
		if ($('#fh5co-products').length > 0) {
			$('#fh5co-products .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var productsWayPoint = function () {
		if ($('#fh5co-products').length > 0) {
			$('#fh5co-products').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {




					setTimeout(function () {
						$('.product-animate-1').addClass('fadeIn animated');
					}, 200);

					setTimeout(function () {
						$('.product-animate-2').addClass('fadeIn animated');
					}, 400);

					setTimeout(productsAnimate, 800);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};

	// Call To Actions Animate
	var ctaAnimate = function () {
		if ($('#fh5co-cta').length > 0) {
			$('#fh5co-cta .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var ctaWayPoint = function () {
		if ($('#fh5co-cta').length > 0) {
			$('#fh5co-cta').waypoint(function (direction) {
				if (direction === 'down' && !$(this).hasClass('animated')) {
					setTimeout(ctaAnimate, 100);
					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};


	// Pricing Animate
	var pricingAnimate = function () {
		if ($('#fh5co-pricing').length > 0) {
			$('#fh5co-pricing .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var pricingWayPoint = function () {
		if ($('#fh5co-pricing').length > 0) {
			$('#fh5co-pricing').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {


					setTimeout(function () {
						$('.pricing-animate-1').addClass('fadeInUp animated');
					}, 100);
					setTimeout(function () {
						$('.pricing-animate-2').addClass('fadeInUp animated');
					}, 400);

					setTimeout(pricingAnimate, 800);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};



	// Features 3 Animate
	var features3Animate = function () {
		if ($('#fh5co-features-3').length > 0) {
			$('#fh5co-features-3 .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var features3WayPoint = function () {
		if ($('#fh5co-features-3').length > 0) {
			$('#fh5co-features-3').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {




					setTimeout(function () {
						$('.features3-animate-1').addClass('fadeIn animated');
					}, 200);

					setTimeout(function () {
						$('.features3-animate-2').addClass('fadeIn animated');
					}, 400);

					setTimeout(function () {
						$('.features3-animate-3').addClass('fadeIn animated');
					}, 600);

					setTimeout(features3Animate, 800);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};

	// Features 3 Animate
	var faqsAnimate = function () {
		if ($('#fh5co-faqs').length > 0) {
			$('#fh5co-faqs .to-animate').each(function (k) {

				var el = $(this);

				setTimeout(function () {
					el.addClass('fadeInUp animated');
				}, k * 200, 'easeInOutExpo');

			});
		}
	};
	var faqsWayPoint = function () {
		if ($('#fh5co-faqs').length > 0) {
			$('#fh5co-faqs').waypoint(function (direction) {

				if (direction === 'down' && !$(this).hasClass('animated')) {




					setTimeout(function () {
						$('.faqs-animate-1').addClass('fadeIn animated');
					}, 200);

					setTimeout(function () {
						$('.faqs-animate-2').addClass('fadeIn animated');
					}, 400);

					setTimeout(faqsAnimate, 800);


					$(this.element).addClass('animated');

				}
			}, {
				offset: '75%'
			});
		}
	};

	// animate-box
	var contentWayPoint = function () {

		$('.animate-box').waypoint(function (direction) {

			if (direction === 'down' && !$(this).hasClass('animated')) {

				$(this.element).addClass('fadeInUp animated');

			}

		}, {
			offset: '75%'
		});

	};


	// Reflect scrolling in navigation
	var navActive = function (section) {
		var el = $('#fh5co-offcanvass > ul');
		el.find('li').removeClass('active');
		el.each(function () {
			$(this).find('a[data-nav-section="' + section + '"]').closest('li').addClass('active');
		});
	};
	var navigationSection = function () {

		var $section = $('div[data-section]');

		$section.waypoint(function (direction) {
			if (direction === 'down') {
				navActive($(this.element).data('section'));

			}
		}, {
			offset: '150px'
		});

		$section.waypoint(function (direction) {
			if (direction === 'up') {
				navActive($(this.element).data('section'));
			}
		}, {
			offset: function () {
				return -$(this.element).height() + 155;
			}
		});

	};






	// Document on load.
	$(function () {

		magnifPopup();
		offCanvass();
		mobileMenuOutsideClick();
		footerFixed();
		faqsAccordion();
		// carouselTestimony();
		// clickMenu();
		// HeaderToggle();

		// Animations
		// introWayPoint();
		// clientWayPoint();
		// featuresWayPoint();
		// features2WayPoint();
		// counterWayPoint();
		// productsWayPoint();
		// features3WayPoint();
		// ctaWayPoint();
		// pricingWayPoint();
		// faqsWayPoint();
		// contentWayPoint();

		// navigationSection();

	});


}());

(function () {
	$(window).on('load', function () {
		$('.cover').fadeOut(1000);
	})

	$('.products-toggle').on('click', function () {
		$('.products-toggle').each(function (index, productToggleEl) {
			$(productToggleEl).removeClass('active');
		})
		$(this).addClass('active');
		var referenceID = $(this).attr('id').replace('infographic-', '');

		$('.infographic-img-wrapper').each(function (index, infographicEl) {
			if ($(infographicEl).attr('infographic') === referenceID) {
				$(infographicEl).addClass('active');
			} else {
				$(infographicEl).removeClass('active');
			}
		})
	})

	$(document).on('mouseenter', '#fnb-svg .hover', function () {
		var refData = $(this).attr('hover-ref');
		var header, text;
		switch (refData) {
			case 'one':
				header = 'A different cloud for everyone';
				text = 'No matter whether your business is in the retail, fashion or F&B industry, Flex.Cloud is your first step toward holistic resource planning and management.';
				break;
			case 'two':
				header = 'Absolute control over your business';
				text = 'As long as you are connected to the cloud, you can monitor your business and make critical decisions on the go. You can observe your daily sales, monitor your best selling products, and push out promotions for your products, anywhere and at any time';
				break;
			case 'three':
				header = 'Obtain essential HR data effortlessly';
				text = 'Supervising your employees can often be difficult and time-consuming, especially for bigger businesses. Flex.Cloud syncs all activity done by your employees to the cloud, making it so much easier for you to do your supervision, payroll and appraisal.';
				break;
			case 'four':
				header = 'Get creative in customer engagement';
				text = 'Start with the simplest membership system or get creative in your unique reward programs. Build a cloud-based CRM system to keep your customers close by.';
				break;
			case 'five':
				header = 'Streamline management process, achieve LEAN';
				text = 'It’s not just about the scale but efficiency and productivity. Flex.HQ is a low-cost solution to cut massive manual reporting and administrative processes. Achieve and stay lean, grow your profit margin.';
				break;
		}

		displayText(header, text);
	})

	$(document).on('mouseenter', '#cloud-svg .hover', function () {
		var refData = $(this).attr('id');
		var header, text;
		switch (refData) {
			case 'bar':
				header = 'BAR';
				text = 'Send your order to the bar in just a second by setting up a simple bar printer. Cheers for no delay.';
				break;
			case 'restaurant':
				header = 'Smart Kitchen System';
				text = 'Busy kitchen deserves a smart kitchen display that helps sorting and managing incoming dishes. Busy restaurant deserves smart runner system that calls queue or controls order delivery.';
				break;
			case 'stock':
				header = 'Inventory Management';
				text = 'The inventory management process for F&B businesses is all about a good balance between practicality and cost control accuracy. Explore our simplified supplier invoice tracker; cost monitoring is easily achieved.';
				break;
			case 'control':
				header = 'Cloud Management';
				text = 'As long as you are connected to the cloud, you can monitor your business and make critical decisions on the go. You can observe your daily sales, monitor your best selling products or change the menu, anywhere and at any time';
				break;
			case 'kiosk':
				header = 'Kiosk';
				text = 'Fancy a cashier-less ordering system? The kiosk is for you. Customers will be able to order and pay for their meals, all by themselves. And they will be glad to know that they can not only pay by cash, but by other cash-less payment methods.';
				break;
			case 'counter':
				header = 'Essential POS Package';
				text = 'Yes, we do have stand-alone, no-nonsense POS package for F&B startup. It is an all-in-one solution for small food stall or café. Start with essential, upgrade when the business expands.';
				break;
			case 'order':
				header = 'Staff Ordering System';
				text = 'Experiencing bottlenecks at the main cashier? Free up these bottlenecks by introducing a staff ordering system, which allows waiters to take and send orders directly through a mobile device assigned to them.';
				break;
			case 'dining':
				header = 'Mobile Ordering System';
				text = 'Don’t want to invest so much in tablets used for ordering? You can get your customer to order with their own device by offering a wifi-based e-Menu. Self-payment can also be achieve by payment gateway integration.';
				break;
		}

		displayText(header, text);
	})

	$(document).on('mouseenter', '#retail-svg .hover', function () {
		var refData = $(this).attr('id');
		var header, text;
		switch (refData) {
			case 'access':
				header = 'Cloud Management';
				text = 'As long as you are connected to the cloud, you can monitor your business and make critical decisions on the go. You can observe your daily sales, monitor your best selling products, and push out promotions for your products, anywhere and at any time.';
				break;
			case 'fashion':
				header = 'Services';
				text = 'For businesses in the service industry, nothing is more important engaging your VIPs. You can sync scheduled appointments from your website to your POS terminal and vice versa, manage loyalty rewards for your valued customers, as well as implement various marketing programs.';
				break;
			case 'roadshows':
				header = 'Events & Roadshows';
				text = "Flex.POS' utility does not only lie within the store; it shines similarly outside of it too. You can employ it at your events and roadshows to record transactions, monitor the number of samples given out or capture customer information. Yes, POS can be as mobile as your business.";
				break;
			case 'outlet':
				header = 'Retail';
				text = 'Minimal downtime, fast transaction and user-friendly system are our take for Retail POS. Our Essential POS Package is ideal for independent retail. Flex-Solver also provides integrated, Customised system for retailers of bigger scale.';
				break;
			case 'store':
				header = 'Self Collection';
				text = 'Customers are now looking for ways to get their goods with more convenience and less hassle. We can help you offer your customers this convenience by integrating your website or ordering system with a self-collection machine.';
				break;
			case 'workplace':
				header = 'E-Commerce';
				text = "Your customers are king, and we want to help you make them feel that way. You can set up a convenient members’ portal for your members to log in and perform various membership functions and collect feedback. Make your customers feel loved, and they'll love you back."
				break;
			case 'inventory':
				header = 'Inventory Management';
				text = 'Inventory management is a key aspect of operation for companies in the retail industry. Keep a tight control over your inventory with an inventory management system that can be integrated with POS and/or even accounting systems.';
				break;
			case 'master':
				header = 'Cloud Membership';
				text = 'Your customers are king, and we want to help you make them feel that way. You can set up a convenient members’ portal for your members to log in and perform various membership functions and collect feedback. Make your customers feel loved, and they’ll love you back.';
				break;
		}

		displayText(header, text);
	})

	$(document).on('mouseleave', '.hover-svg .hover', function () {
		hideHeader();
	});

	function displayText(header, text) {
		$('.section.products .panel .content .heading').html(header);
		$('.section.products .panel .content .subtext').html(text);
		$('.section.products .panel').css('opacity', 1);
	}

	function hideHeader() {
		$('.section.products .panel').css('opacity', 0);
	}
})();